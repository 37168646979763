import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import gmovers512 from "../assets/gmovers512.png";
import { Typography } from "@mui/material";
import gmoversLogoWhite from "../assets/gmoversLogoWhite.png";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { createTheme, colors, ThemeProvider } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import newlogo from "../assets/logos/newlogo.png";
import { useNavigate } from "react-router-dom";

//css
import "../css/navbar.css";

function appBarLabel(label) {
  return (
    <Toolbar>
      <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
        <MenuIcon />
      </IconButton>
      <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
        {label}
      </Typography>
    </Toolbar>
  );
}

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#1976d2",
    },
  },
});

const NavigationBar = () => {
  const navigate = useNavigate();
  const style = {
    img: {
      height: "100%",
      cursor: "pointer",
    },
    imgContainer: {
      height: "30px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      // marginLeft: "50px",
    },
    brandName: {
      display: "grid",
      placeItems: "center",
      color: "white",
      fontWeight: 700,
      fontSize: "20px",
      color: "rgba(11, 138, 28, 0.904)",
    },
  };
  return (
    // <Grid container spacing={2}>
    //   {/* <div
    //     style={{
    //       display: "flex",
    //       justifyContent: "center",
    //       alignItems: "center",
    //     }}
    //   >
    //     <div
    //       style={{
    //         display: "flex",
    //         gap: 20,
    //         backgroundColor: "#212322",
    //         width: "100%",
    //       }}
    //     >
    //       <img src={gmoversLogoWhite} width="50" height="50" />
    //       <Typography
    //         style={{
    //           color: "white",
    //           margin: "auto 0",
    //           fontSize: "20px",
    //           fontWeight: 700,
    //           display: "grid",
    //           placeItems: "center",
    //         }}
    //         variant="overline"
    //       >
    //         GREEN MOVERS
    //       </Typography>
    //     </div>
    //   </div> */}
    //   <Stack spacing={2} sx={{ flexGrow: 1 }}>
    //     <ThemeProvider theme={darkTheme}>
    //       <div style={{ marginTop: "10px" }}>
    //         <AppBar position="static" color="primary">
    //           <div style={{widtj}}>
    //             <img src={gmoversLogoWhite} style={{ width: "100%" }} />
    //           </div>
    //           {appBarLabel("GREEN MOVERS")}
    //         </AppBar>
    //       </div>
    //     </ThemeProvider>
    //   </Stack>
    // </Grid>
    <div className="navbar">
      <Grid container spacing={2}>
        <Grid item md={3}>
          <div style={style.imgContainer}>
            <img
              src={newlogo}
              style={style.img}
              onClick={() => navigate("/")}
            />
            <Typography style={style.brandName} variant="overline">
              {/* GREEN MOVERS */}
            </Typography>
          </div>
        </Grid>
        <Grid item md={6}>
          <Button
            onClick={() => navigate("/")}
            variant="text"
            style={{ color: "green", fontWeight: 700 }}
          >
            Home
          </Button>
          <Button variant="text" style={{ color: "green", fontWeight: 700 }}>
            Driver App
          </Button>
          <Button variant="text" style={{ color: "green", fontWeight: 700 }}>
            User App
          </Button>
          <Button variant="text" style={{ color: "green", fontWeight: 700 }}>
            About
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default NavigationBar;
