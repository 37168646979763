import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

//images
import gm3 from "../../components/assets/cardImages/gm3.jpg";
import taxibanner from "../../components/assets/banners/taxibanner.png";

//css
import "../../components/css/errand.css";
const Errand = () => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <div className="deliverybanner">
            <Typography
              style={{
                fontSize: "30px",
                textTransform: "capitalize",
                color: "white",
                fontFamily: "Anton,sans-serif",
              }}
              className="testing"
            >
              {" "}
              {/* FAST DELIVERY SERVICES */}
            </Typography>
          </div>
        </Grid>

        <Grid item container md={12}>
          <div style={{ display: "flex", marginTop: "90px" }}>
            <Grid item md={6}>
              <div
                style={{
                  width: "680px",
                  marginTop: "170px",
                  padding: "20px",
                  display: "flex",
                  justifyContent: "center",
                  margin: "0 auto",
                }}
              >
                <img
                  src={taxibanner}
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </Grid>
            <Grid md={6}>
              <Typography variant="body1" style={{ fontSize: "24px" }}>
                Why Choose GMOVERS?
              </Typography>
              <Typography variant="h2" style={{ fontWeight: 700 }}>
                List it, we'll buy and deliver it for you
              </Typography>
              <div style={{ paddingLeft: "50px" }}>
                <Typography
                  variant="body1"
                  style={{ fontSize: "34px", marginTop: "20px" }}
                >
                  Services
                </Typography>
                <div>
                  <li style={{ fontSize: "24px" }}>Padala</li>
                  <li style={{ fontSize: "24px" }}>Pabili</li>
                  <li style={{ fontSize: "24px" }}>Pabayad</li>
                </div>
              </div>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Errand;
