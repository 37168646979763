import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import CarTaxi from "../assets/CarTaxi.png";
import CarTaxi2 from "../assets/CarTaxi2.png";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  HashRouter,
} from "react-router-dom";
import Link from "@mui/material/Link";
import IdolTaxi1 from "../assets/IdolTaxi1.jpg";
import bannerGmovers1 from "../assets/bannerGmovers1.png";

import bannerGmovers2 from "../assets/bannerGmovers2.png";

import "../css/landingPage.css";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";

// images

import taxi from "../assets/img1.png";
import errand from "../assets/img2.png";
import delivery from "../assets/img3.png";
import ride from "../assets/img4.png";
import market from "../assets/img5.png";

//square images
import gm1 from "../assets/cardImages/gm1.jpg";
import gm2 from "../assets/cardImages/gm2.jpg";
import gm3 from "../assets/cardImages/gm3.jpg";
import gm4 from "../assets/cardImages/gm4.jpg";
import gm5 from "../assets/cardImages/gm5.jpg";
import gm6 from "../assets/cardImages/gm6.jpg";
import gm7 from "../assets/cardImages/gm7.jpg";
import gm8 from "../assets/cardImages/gm8.jpg";

import playstorebtn from "../assets/playstorebtn.png";
import playstoreapple from "../assets/playstoreapple.png";

// images 2
import taxi2 from "../assets/img1.1.png";
import errand2 from "../assets/img2.1.png";
import delivery2 from "../assets/img3.1.png";
import ride2 from "../assets/img4.1.png";
import market2 from "../assets/img5.1.png";
import { fontWeight } from "@mui/system";

// banners
import bannerGmovers3 from "../assets/bannerGmovers3.png";
import groceryBanner from "../assets/groceryBanner.jpg";
import gmoversBanner from "../assets/gmoversBanner.jpg";
import scan from "../assets/scan.png";
import scan2 from "../assets/scan2.png";
import scan3 from "../assets/scan3.png";
import scan4 from "../assets/scan4.png";

//img white icons
import taxi3 from "../assets/whiteIcons/taxi.png";
import errands3 from "../assets/whiteIcons/errands.png";
import delivery3 from "../assets/whiteIcons/delivery.png";
import ride3 from "../assets/whiteIcons/ride.png";
import market3 from "../assets/whiteIcons/market.png";

import newlogo from "../assets/newlogo.png";
// components
import About from "../about/About";

const LandingPage = () => {
  const navigate = useNavigate();
  const left = {
    // backgroundColor: "#82CD47",
    height: "300px",
  };
  const right = {
    backgroundColor: "white",
    height: "300px",
  };

  const data = [
    { img: taxi2, name: "TAXI", sub: "Get a TAXI to your desitination" },
    {
      img: errand2,
      name: "ERRAND",
      sub: "List it, we'll buy and deliver it for you",
    },
    { img: delivery2, name: "DELIVERY", sub: "Point to Point Delivery" },
    { img: ride2, name: "RIDE", sub: "Book your prefered vehicle" },
    { img: market2, name: "MARKET", sub: "Shop here and we will deliver" },
  ];
  const cardImages = [
    { img: gm1, name: "", sub: "" },
    { img: gm2, name: "", sub: "" },
    { img: gm3, name: "", sub: "" },
    { img: gm4, name: "", sub: "" },
    { img: gm5, name: "", sub: "" },
    { img: gm6, name: "", sub: "" },
    { img: gm7, name: "", sub: "" },
    { img: gm8, name: "", sub: "" },
  ];

  const whiteIcons = [
    {
      img: taxi3,
      name: " TAXI",
      sub: "Get a TAXI to your desitination",
      route: "/taxiservices",
    },
    {
      img: errands3,
      name: "ERRANDS",
      sub: "List it, we'll buy and deliver it for you",
      route: "/errandservices",
    },
    {
      img: delivery3,
      name: "DELIVERY",
      sub: "Point to Point Delivery",
      route: "/deliveryservices",
    },
    {
      img: ride3,
      name: "RIDE",
      sub: "Book your prefered vehicle",
      route: "/taxiservices",
    },
    {
      img: market3,
      name: "MARKET",
      sub: "Shop here and we will deliver",
      route: "/marketservices",
    },
  ];
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <div></div>
          <div className="mybanner" style={{ width: "100%", height: "100%" }}>
            <img
              src={gmoversBanner}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </div>
        </Grid>
        {/* <Container>
          <Row>
            <Col md={6} style={left}>

            </Col>
            <Col md={6} style={right}>
              <img src={bannerGmovers3} id="car" />
            </Col>
          </Row>
        </Container> */}
        {/* <Grid item md={12}>
          <div
            style={{
              marginTop: "50px",
              width: "100%",
              overflow: "hidden",
            }}
          >
            <Grid item container spacing={3} md={12}>
              {data.map((val, index) => {
                return (
                  <Grid item md={3}>
                    <div
                      style={{
                        width: "50px",
                        display: "flex",
                        justifyContent: "center",
                        margin: "0 auto",
                        flexDirection: "column",
                        cursor: "pointer",
                        padding: "5px",
                      }}
                    >
                      <img className="hover" src={val.img} />
                      <Typography
                        variant="overline"
                        style={{ textAlign: "center", fontWeight: 700 }}
                      >
                        {val.name}
                      </Typography>
                    </div>
                    <Typography variant="body2" style={{ textAlign: "center" }}>
                      {val.sub}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </Grid> */}
        <Grid item md={12} style={{ position: "relative" }}>
          <div style={{ marginTop: "50px" }}></div>
          <Typography style={{ textAlign: "center", fontWeight: 700 }}>
            {/* GREEN MOVERS APP */}
          </Typography>

          <div className="scan">
            <div>
              <img
                src={scan4}
                style={{ width: "100%", height: "300px", objectFit: "contain" }}
              />
            </div>
            <div
              style={{
                // position: "absolute",
                // left: 550,
                top: 70,
                display: "flex",
                flexDirection: "column",
                gap: 20,
                margin: "auto 0",
              }}
            >
              <img src={newlogo} style={{ width: "250px" }} />

              <Typography style={{ width: "300px", textAlign: "justify" }}>
                We connect customers through our services: ride hailing,
                transporting goods, and grocery shopping.
              </Typography>

              <div style={{ display: "flex", gap: 20 }}>
                <a href="https://apps.apple.com/ph/app/gmovers/id1546471529">
                  <img
                    className="playstore"
                    src={playstoreapple}
                    style={{ width: "120px", cursor: "pointer" }}
                  />
                </a>

                <a href="https://play.google.com/store/apps/details?id=ngc.japoo.fleet&hl=en">
                  <img
                    className="playstore"
                    src={playstorebtn}
                    style={{ width: "120px", cursor: "pointer" }}
                  />
                </a>
              </div>
            </div>
          </div>
          <div
            className="backdrop"
            style={{ height: "300px", backgroundColor: "#212322" }}
          >
            <Grid item md={12} container>
              <div style={{ width: "100%" }}>
                <Typography
                  style={{
                    color: "white",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "40px",
                    fontSize: "20px",
                    fontWeight: 700,
                  }}
                  variant="overline"
                >
                  GMOVERS OFFERS
                </Typography>
              </div>
            </Grid>
            <Grid item md={12} container spacing={2}>
              <div className="whiteIcons">
                {whiteIcons.map((val, index) => {
                  return (
                    <Grid item md={2}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <img
                          src={val.img}
                          style={{
                            width: "50px",
                            display: "flex",
                            justifyContent: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            navigate(val.route);
                          }}
                        />
                        <Typography style={{ color: "white" }}>
                          {val.name}
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{ color: "white", textAlign: "center" }}
                        >
                          {val.sub}
                        </Typography>
                      </div>
                    </Grid>
                  );
                })}
              </div>
            </Grid>
          </div>
        </Grid>
        <Grid item container spacing={3} md={12}>
          <Grid item md={12}>
            <div style={{ width: "100%" }}>
              <Typography
                style={{ textAlign: "center", fontWeight: 700 }}
              ></Typography>
            </div>
          </Grid>
          {cardImages.map((val, index) => {
            return (
              <Grid item md={3}>
                <div
                  style={{
                    width: "300px",
                    display: "flex",
                    justifyContent: "center",
                    margin: "0 auto",
                    padding: "16px",
                  }}
                >
                  <img
                    src={val.img}
                    style={{ width: "100%", borderRadius: "8px" }}
                  />
                </div>
              </Grid>
            );
          })}
        </Grid>

        {/* <Grid item container md={12}>
          <About />
        </Grid> */}
      </Grid>
    </div>

    // <Grid container spacing={2}>
    //   <Grid item md={12}>
    //     <div
    //       style={{
    //         position: "absolute",
    //         top: "230px",
    //         left: "50px",
    //         width: "500px",
    //       }}
    //     >
    //       <Typography variant="h3" style={{ color: "white" }}>
    //         Connect, Earn and Contribute
    //       </Typography>
    //     </div>
    //     <div style={{ width: "100vw" }}>
    //       {/* <img src={bannerGmovers2} style={{ width: "100%", height: "60%" }} /> */}
    //     </div>
    //   </Grid>
    //   <Grid item md={12}>
    //     <div
    //       style={{
    //         display: "flex",
    //         justifyContent: "center",
    //         gap: 20,
    //         padding: 20,
    //       }}
    //     >
    //       <Grid item md={3}>
    //         <div>
    //           <Card>
    //             <CardContent>
    //               <img src={taxi} width="100%" />
    //               <Typography variant="overline" style={{}}>
    //                 Taxi
    //               </Typography>
    //             </CardContent>
    //           </Card>
    //         </div>
    //       </Grid>
    //       <Grid item md={3}>
    //         <div>
    //           <Card>
    //             <CardContent>
    //               <img src={errand} width="100%" />
    //               <Typography variant="overline" style={{}}>
    //                 Errand
    //               </Typography>
    //             </CardContent>
    //           </Card>
    //         </div>
    //       </Grid>
    //       <Grid item md={3}>
    //         <div>
    //           <Card>
    //             <CardContent>
    //               <img src={delivery} width="100%" />
    //               <Typography variant="overline" style={{}}>
    //                 Delivery
    //               </Typography>
    //             </CardContent>
    //           </Card>
    //         </div>
    //       </Grid>
    //       <Grid item md={3}>
    //         <div>
    //           <Card>
    //             <CardContent>
    //               <img src={ride} width="100%" />
    //               <Typography variant="overline" style={{}}>
    //                 Ride
    //               </Typography>
    //             </CardContent>
    //           </Card>
    //         </div>
    //       </Grid>
    //       <Grid item md={3}>
    //         <div>
    //           <Card>
    //             <CardContent>
    //               <img src={market} width="100%" />
    //               <Typography variant="overline" style={{}}>

    //               </Typography>
    //             </CardContent>
    //           </Card>
    //         </div>
    //       </Grid>
    //     </div>
    //   </Grid>
    // </Grid>
  );
};

export default LandingPage;
