import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

//css
import "../../components/css/delivery.css";
const DeliveryPage = () => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <div className="deliverybanner">
            <Typography
              style={{
                fontSize: "30px",
                textTransform: "capitalize",
                color: "white",
                fontFamily: "Anton,sans-serif",
              }}
              className="testing"
            >
              {" "}
              {/* FAST DELIVERY SERVICES */}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default DeliveryPage;
