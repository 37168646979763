import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
//images banner

import sedan from "../../components/assets/sedan.png";
import howitworks from "../../components/assets/banners/howitworks.png";

//icons
import SwipeLeftIcon from "@mui/icons-material/SwipeLeft";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PaidIcon from "@mui/icons-material/Paid";
//css
import "../../components/css/taxipage.css";
const IndexTaxi = () => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <div className="banner">
            <div className="testing">
              <Typography
                style={{
                  fontSize: "50px",
                  textTransform: "capitalize",
                  color: "white",
                  fontFamily: "Anton,sans-serif",
                }}
              >
                {" "}
                RELIABLE TAXI SERVICES IN BATANGAS
              </Typography>
              <Typography variant="body2" style={{ color: "white" }}>
                Quickly make contact with a delivery driver. They'll show up
                quickly at the location you've designated for pickup.
              </Typography>
              <Button>
                <a
                  className="download"
                  href="https://play.google.com/store/apps/details?id=ngc.japoo.fleet&hl=en"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  DOWNLOAD APP HERE
                </a>
              </Button>
            </div>
          </div>
        </Grid>
        <Grid
          item
          container
          spacing={2}
          md={12}
          // style={{ backgroundColor: "#E5E0FF" }}
        >
          <Grid item md={6}>
            <div
              style={{
                width: "300px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 auto",
                marginTop: "150px",
              }}
            >
              <img src={sedan} style={{ width: "100%", height: "100%" }} />
            </div>
          </Grid>
          <Grid item md={6}>
            <div
              style={{
                display: "flex",
                marginTop: "80px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <Typography> Price:</Typography>
                </Grid>
                <Grid item md={8}>
                  Batangas City <br /> Flag Down Rate: Php 45.00
                </Grid>
                <Grid item md={1}>
                  <Divider />
                </Grid>
                <Grid item md={3}>
                  <Typography> Rate/Km:</Typography>
                </Grid>
                <Grid item md={9}>
                  Php 13.50
                </Grid>
                <Grid item md={3}>
                  <Typography> Sitting Capacity:</Typography>
                </Grid>
                <Grid item md={9}>
                  4 persons
                </Grid>
                <Grid item md={3}>
                  <Typography> Size Limit :</Typography>
                </Grid>
                <Grid item md={9}>
                  3.2 × 1.9 × 2.3 ft
                </Grid>
                <Grid item md={3}>
                  <Typography> Route:</Typography>
                </Grid>
                <Grid item md={9}>
                  Region IV
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        className="howitworksContainer"
        container
        spacing={2}
        style={{
          backgroundColor: "#EEF1FF",
          marginTop: "50px",
        }}
      >
        <div
          style={{
            marginTop: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            // backgroundColor: "#BCE29E",
          }}
        >
          <Typography
            style={{ fontWeight: 700, fontSize: "30px", color: "white" }}
          >
            How it Works?
          </Typography>
        </div>
        <div
          style={{
            width: "80vw",
            padding: "10px 0 10px 0",
            display: "flex",
            justifyContent: "center",
            margin: "0 auto",
            gap: "50px",
          }}
        >
          <Grid item container spacing={2} style={{ marginBottom: "150px" }}>
            <Grid item md={3}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <SwipeLeftIcon
                  style={{
                    color: "#C98474",
                    fontSize: "70px",
                    padding: "10px",
                  }}
                />
                <Typography style={{ fontWeight: 700 }}>
                  Book in just 2 Tabs
                </Typography>
                <Typography variant="caption" style={{ textAlign: "center" }}>
                  "Choose a service", <br /> "Select pickup and dropoff
                  location"
                </Typography>
              </div>
            </Grid>
            <Grid item md={3}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <DriveEtaIcon
                  style={{
                    color: "#7895B2",
                    fontSize: "70px",
                    padding: "10px",
                  }}
                />
                <Typography style={{ fontWeight: 700 }}>
                  Get a Driver
                </Typography>
                <Typography variant="caption" style={{ textAlign: "center" }}>
                  "Select the nearest driver", <br /> "Check driver ratings"
                </Typography>
              </div>
            </Grid>
            <Grid item md={3}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <LocationOnIcon
                  style={{
                    color: "#B1B2FF",
                    fontSize: "70px",
                    padding: "10px",
                  }}
                />
                <Typography style={{ fontWeight: 700 }}>
                  Track your Travel
                </Typography>
                <Typography variant="caption" style={{ textAlign: "center" }}>
                  "Track your travel destination", <br /> "Rate driver"
                </Typography>
              </div>
            </Grid>
            <Grid item md={3}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <PaidIcon
                  style={{
                    color: "#FF9494",
                    fontSize: "70px",
                    padding: "10px",
                  }}
                />
                <Typography style={{ fontWeight: 700 }}>
                  Arrive Safely
                </Typography>
                <Typography variant="caption" style={{ textAlign: "center" }}>
                  "Pay for the service", <br /> "Provide Feedback"
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </div>
  );
};

export default IndexTaxi;
