import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  HashRouter,
} from "react-router-dom";
import Grid from "@mui/material/Grid";
import { createTheme, colors, ThemeProvider } from "@mui/material";

import UnderConstruction from "./components/underconstruction/UnderConstruction";

import LandingPage from "./components/home/LandingPage";
import NavigationBar from "./components/home/NavigationBar";
import Footer from "./components/home/Footer";

//import pages
import IndexTaxi from "./pages/indexTaxiPage/IndexTaxi";
import DeliveryPage from "./pages/deliveryPage/DeliveryPage";
import Errand from "./pages/errandPage/Errand";

const theme = createTheme({
  palette: {
    secondary: {
      main: "#fafafa",
    },
    primary: {
      main: "#00351E",

      contrastText: "white",
    },
  },
});
function App() {
  return (
    <ThemeProvider theme={theme}>
      <div>
        <HashRouter>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <div>{<NavigationBar />}</div>

              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/taxiservices" element={<IndexTaxi />} />
                <Route path="/deliveryservices" element={<DeliveryPage />} />
                <Route path="/errandservices" element={<Errand />} />
              </Routes>

              {/* <div>{<Footer />}</div> */}
            </Grid>
          </Grid>
        </HashRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
